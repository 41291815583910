import { UrlSeoService } from '../SEOParamService';

type UrlSeoServiceParams = ConstructorParameters<typeof UrlSeoService>[number];

const searchPathParamConfig: UrlSeoServiceParams = [
  { queryParam: 'location', defaultString: 'ireland' },
  'propertyType',
];

/**
 * Use this to construct a new instance of the UrlSeoService for search pages. This can be used to
 * generate SEO-friendly URLs to link to our search pages. This makes working with filters a bit
 * easier.
 *
 * If you need to create an instance with different parameters, please import the UrlSeoService
 * yourself and construct it with the parameters you need. This factory is intentionally limited
 * to our well-known search page URL query params and how they interact with filters.
 */
export const createSearchUrlSeoService = () => {
  return new UrlSeoService(searchPathParamConfig);
};

/**
 * @deprecated please construct your own instance using the createSearchUrlSeoService factory for
 * this.
 *
 * This shared service means this module is stateful and shared between multiple pages/routes.
 * This will lead to headaches so we should instead try and construct this object when necessary.
 * Ideally it can be used in a pure way without things like updateCurrentValue.
 */
const SearchSEOParams = createSearchUrlSeoService();

export default SearchSEOParams;
