export type ParamObject = {
  /**
   * A name like "location", or "propertyType"
   */
  queryParam: string;

  /**
   * This is just `${queryParam}Path`.
   */
  pathString: string;

  /**
   * If present, will be used if the queryParam is not set.
   */
  defaultString: string;

  /**
   * The most-recently-saved value of a query param.
   *
   * Set using updateCurrentValue(queryParam, newValue)
   */
  currentValue: string | string[];
};

type ThingOrThingArray<T> = T | T[];
type QueryParamValue = ThingOrThingArray<string | number | boolean>;
export type QueryObject = Record<string, QueryParamValue>;

export class UrlSeoService {
  public paramObjects: ParamObject[];
  public get pathStrings() {
    return this.paramObjects.map((paramObject) => paramObject.pathString);
  }

  constructor(
    queryParams: (string | { queryParam: string; defaultString: string })[],
  ) {
    const normalizedParams = queryParams.map(
      (
        unnormalizedQueryParam:
          | string
          | { queryParam: string; defaultString: string },
      ) => {
        if (typeof unnormalizedQueryParam === 'string') {
          return { queryParam: unnormalizedQueryParam, defaultString: null };
        }
        return unnormalizedQueryParam;
      },
    );

    this.paramObjects = normalizedParams.map(
      ({ queryParam, defaultString }) => {
        const pathString = `${queryParam}Path`;
        return {
          queryParam,
          pathString,
          defaultString,
          currentValue: [],
        };
      },
    );
  }

  public getParamObject(name: string) {
    return this.paramObjects.find(
      (paramObject) => paramObject.queryParam === name,
    );
  }

  // queryObject is actually more like Record<string, unknown[]>
  public reconcileSEOMultiParamsFromURL(queryObject: any) {
    const query = { ...queryObject };

    this.paramObjects.forEach((paramObject) => {
      const pathNameValue = query[paramObject.pathString];
      const queryValues = query[paramObject.queryParam] || [];
      if (pathNameValue) {
        query[paramObject.queryParam] = queryValues.length
          ? [pathNameValue, ...(queryValues as string[])]
          : pathNameValue;
        delete query[paramObject.pathString];
      }
    });

    return query;
  }

  /**
   * Given a param and new value, will overwrite the current value of the param.
   *
   * Note that this function will ONLY care about and do something with a param that is in the
   * paramObjects array. If the param is not in the array, this function will do nothing.
   */
  public updateCurrentValue(
    queryParam: string,
    currentValue: string | string[],
  ) {
    const paramObjectIndex = this.paramObjects.findIndex(
      (paramObject) => paramObject.queryParam === queryParam,
    );

    if (paramObjectIndex !== -1) {
      this.paramObjects[paramObjectIndex].currentValue = currentValue;
    }
  }

  public resetAllParams() {
    this.paramObjects.forEach((paramObject) => {
      paramObject.currentValue = [];
    });
  }

  /**
   * Similar to toUrlStateful, this function returns a URL for use in a link or with the Next
   * router. However, this function intentionally bypasses any current values stored in the
   * paramObjects. It will only look in the queryOverrides object for values. This is good for when
   * you want to derive multiple URLs without calling updateCurrentValue before each one.
   *
   * Importantly, the queryOverrides object should contain any and all values that you want to
   * include in the URL!
   */
  public toUrlStateless(
    baseUrl: string,
    queryParameters: QueryObject = {},
  ): string {
    baseUrl = baseUrl.startsWith('/') ? baseUrl : `/${baseUrl}`;
    let urlPath = '';

    // Take a deep clone so we don't accidentally modify the original object which largely comes
    // from props.
    const mutableQueryClone = structuredClone(queryParameters);

    for (const { queryParam, pathString, defaultString } of this.paramObjects) {
      // We only use the pathStrings here for consistency. If both are defined on the
      // queryParameters, we'll log an error in dev mode to help developers avoid this mistake.
      const pathValue = queryParameters[pathString];

      // When we find the first paramObject that has an invalid or missing value, we should stop
      // adding to the pathname - everything else is a query param at this point. This is a common
      // and very valid scenario that usually occurs when there are multiple values for some
      // param. For example, if we have a locationPath of "kerry" and "dublin", we will generate a
      // URL like this:
      //  /property-for-sale/ireland?propertyType=house&location=dublin&location=kerry
      //
      // Note1: above that the propertyType would normally come after the location, but since we have
      // multiple values for location, we need to put propertyType in the query string as well.
      // Note2: we do still include the default value for the path param if it's specified.
      const valueForUseInPath = isValidUrlPathValue(pathValue)
        ? pathValue
        : defaultString;

      if (!valueForUseInPath) {
        break;
      }

      // Dev-only warning to help avoid issues until this is fully deprecated.
      // This will be stripped out of the production build.
      if (process.env.NODE_ENV !== 'production') {
        const queryParamValue = queryParameters[queryParam];
        if (queryParamValue) {
          // eslint-disable-next-line no-console
          console.error(
            `URLSeoService.toUrlStateless: ${queryParam} is defined in queryOverrides. Please use ${pathString} instead.`,
          );
        }
      }

      urlPath += `/${valueForUseInPath}`;

      // If this value was included in the path, remove it from the query object.
      delete mutableQueryClone[pathString];

      // Special case: if this param's default value was the one used in the path, and
      // this value is an array, we should re-alias the param without the 'Path' suffix so it gets
      // serialized into the params properly.
      if (
        valueForUseInPath === defaultString &&
        Array.isArray(pathValue) &&
        pathValue.length > 1
      ) {
        mutableQueryClone[queryParam] = pathValue;
      }
    }

    // Do another quick pass to re-alias any path params still left in the query object. This is needed
    // because we broke out of the previous loop early if we found an invalid path param.
    // Rather than trying to add more cases to that already-complicated loop, we'll just do this
    // quick pass here.
    for (const { queryParam, pathString } of this.paramObjects) {
      if (mutableQueryClone[pathString]) {
        mutableQueryClone[queryParam] = mutableQueryClone[pathString];
        delete mutableQueryClone[pathString];
      }
    }

    // At this point, everything left in the query object is a query param.
    const searchString = generateQueryParamsString(mutableQueryClone);

    return joinPathAndQuery(baseUrl + urlPath, searchString);
  }

  /**
   * Similar to toUrlStateless, this function creates an SEO-friendly URL from the current state of
   * this service. This function takes the current state of this service object into account when
   * doing so. Note that the paramObjects are appended into the URL's path, while any other query
   * parameters are added to the query string.
   *
   * Search engines only care about the URL path, not query parameters.
   *
   * Currently SeoParamService only tracks the location and property type, but our SEO URLs have 3
   * path segments: section/location/propertyType.
   */
  public toUrlStateful(baseUrl: string, queryOverrides: QueryObject = {}) {
    baseUrl = baseUrl.startsWith('/') ? baseUrl : `/${baseUrl}`;
    const legacyQueryObject = this.makeReconciledQueryObject(
      queryOverrides,
    ) as Record<string, string | string[]>;

    let urlPath = '';
    this.paramObjects.forEach(({ pathString }) => {
      const pathParamValue = legacyQueryObject[pathString];
      // If there's a value and it's not an array, we append it to the path.
      // Otherwise, we want to put the multi-value path param in the query string.
      if (pathParamValue && !Array.isArray(pathParamValue)) {
        urlPath += `/${legacyQueryObject[pathString]}`;
        delete legacyQueryObject[pathString];
      }
    });

    // Any extra parameters get added into the query string directly.
    const searchString = generateQueryParamsString(legacyQueryObject);

    return joinPathAndQuery(baseUrl + urlPath, searchString);
  }

  /**
   * @deprecated use toUrl instead. This method should likely be private.
   */
  public makeReconciledQueryObject(queryObject: QueryObject) {
    let query = { ...queryObject };
    let isMultiParam = false;

    // if any parameters (other than the last one) have more than one value, set isMultiParam to true
    // this is used to switch everything to query params
    this.paramObjects.forEach(({ currentValue }, index) => {
      if (index === this.paramObjects.length - 1) {
        return;
      }
      if (
        typeof currentValue !== 'string' &&
        currentValue &&
        currentValue.length > 1
      ) {
        isMultiParam = true;
      }
    });

    this.paramObjects.forEach(
      ({ currentValue = [], pathString, queryParam, defaultString }) => {
        const isValidCurrentValue = currentValue.length && currentValue[0];

        // if no valid value, either add the default string, or remove the properties that we aren't using
        if (!isValidCurrentValue) {
          if (defaultString) {
            query = {
              ...query,
              [pathString]: defaultString,
            };
            delete query[queryParam];
          } else {
            delete query[pathString];
            delete query[queryParam];
          }
          return query;
        }

        // determine whether we need to use a path query for a single value
        // or a query string for multiple values
        const useQueryParams =
          typeof currentValue !== 'string' &&
          (currentValue.length > 1 || isMultiParam);

        const name = useQueryParams ? queryParam : pathString;
        if (useQueryParams) {
          delete query[pathString];
          if (defaultString) {
            query = {
              ...query,
              [pathString]: defaultString,
            };
          }
        } else {
          delete query[queryParam];
        }

        query = {
          ...query,
          [name]: currentValue.length === 1 ? currentValue[0] : currentValue,
        };
      },
    );

    // These geoSearchTypes are all handled specially so we don't need a radius.
    // BBOX is a bounding box search i.e. user has zoomed/panned the map
    // CUSTOM_SHAPES is a custom shape sent by the server i.e. Roscommon, South Dublin, etc.
    if (
      queryObject.geoSearchType === 'BBOX' ||
      queryObject.geoSearchType === 'CUSTOM_SHAPES' ||
      queryObject.geoSearchType === 'BBOX_CUSTOM_SHAPES'
    ) {
      query.locationPath = 'mapArea';
      delete query.radius;
    }
    return query;
  }
}

function joinPathAndQuery(path: string, query: string): string {
  return query ? `${path}?${query}` : path;
}

/**
 * If the value is an array, we should format it like this:
 * location=loc1&location=loc2&location=loc3.
 *
 * We cannot use URLSearchParams here since it will encode them as a comma-separated string.
 */
function generateQueryParamsString(params: QueryObject): string {
  const paramsAsStringArray = Object.entries(params).flatMap(
    ([paramKey, paramValue]) => {
      // If it's invalid, return an empty array so flatMap will ignore it.
      if (!isValidUrlValue(paramValue)) {
        return [];
      }
      if (Array.isArray(paramValue)) {
        return paramValue.map((value) => genQueryPair(paramKey, value));
      } else {
        return [genQueryPair(paramKey, paramValue)];
      }
    },
  );
  return paramsAsStringArray.join('&');
}

/**
 * Generates a key-value pair for a query string.
 */
function genQueryPair(key: string, value: string | number | boolean) {
  return `${key}=${value}`;
}

/**
 * Empty string, empty array, or null/undefined are all considered invalid values.
 *
 * The number 0 and boolean false are considered valid values.
 */
function isValidUrlValue(pathValue: QueryParamValue): boolean {
  // Sorry grandmothers, but we don't allow NaNs in our URLs.
  const isNotNan = !Number.isNaN(pathValue);

  // This check is a clever way to check for all of these cases and it also ensures falsy values
  // like the number 0 are considered valid.
  const hasValue = Boolean('' + [pathValue]);

  // If the array is length === 1, we can include it in the path, if it's 0 or >1, we'll include it
  // as query params.
  // const ifArrayIsNotEmpty = !Array.isArray(pathValue) || pathValue.length > 0;

  return isNotNan && hasValue;
}

function isValidUrlPathValue(pathValue: QueryParamValue): boolean {
  return (
    isValidUrlValue(pathValue) &&
    // We also don't want Arrays with length > 1 in the path. Empty arrays are checked by the
    // previous function.
    (!Array.isArray(pathValue) || pathValue.length === 1)
  );
}
