import axios from 'axios';

import { setAuthHeaders } from '../../api';
import { baseHeaders } from '../../helpers/utils';

import { AnalyticsEventFull } from './AnalyticsTypes';

export default class AnalyticsService {
  api_url_property_details: string;

  constructor(api_url_property_details: string) {
    this.api_url_property_details = api_url_property_details;
  }
  // At the moment this is slightly tied to the property details page
  // but in the future we can make it more generic by using TS overloads
  public logApolloEvent(analyticsEvent: AnalyticsEventFull) {
    try {
      axios({
        url: `${this.api_url_property_details}/old/v1/tracking`,
        method: 'POST',
        headers: {
          ...baseHeaders,
          ...setAuthHeaders(analyticsEvent.token || null),
        },
        data: analyticsEvent,
      });
    } catch (error) {
      console.warn('From AnalyticsService', error);
    }
  }

  public pushToDataLayer(data: {
    [key: string]: string | boolean | number | string[] | object | undefined;
  }) {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({ ...data });
    }
  }
}

// TODO: This is the same as the above, decide which one to use and replace in components
class GoogleAnalyticsService {
  public pushToDataLayer(data: {
    [key: string]: string | boolean | number | string[] | undefined;
  }) {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({ ...data });
    }
  }
}

export { GoogleAnalyticsService };
